import Http from '../utils/Http';

const apiUrl = process.env.REACT_APP_BARRAMENTO_CONTROLLER_URL || 'http://0.0.0.0:8080';
let http;

class BarramentoService {
  constructor() {
    http = new Http(apiUrl);
  }

  save(payload) {
    return http.post('/cadastro-organico', payload);
  }

  getTerm() {
    return http.get('/term/store-integration');
  }
}

export default new BarramentoService();

const BITRIX_SEGMENTS = [
  {key: 'ALIMENTAR', value: 'Alimentar'},
  {key: 'AUTOMOTIVA', value: 'Automotiva'},
  {key: 'FARMA', value: 'Farma'},
  {key: 'FOODSERVICE', value: 'Food Service'},
  {key: 'LIVROS', value: 'Livros'},
  {key: 'MATCON', value: 'MatCon'},
  {key: 'MODA', value: 'Moda'},
];


const BITRIX_SCHEDULING_PERIOD = [
  {key: 'MORNING', value: 'Manhã'},
  {key: 'AFTERNOON', value: 'Tarde'},
];

const BITRIX_SCHEDULING_TIME =
{
  'MORNING': [
    {key: '09:00', value: '09:00'},
    {key: '10:00', value: '10:00'},
    {key: '11:00', value: '11:00'}],
  'AFTERNOON': [
    {key: '12:00', value: '12:00'},
    {key: '13:00', value: '13:00'},
    {key: '14:00', value: '14:00'},
    {key: '15:00', value: '15:00'},
    {key: '16:00', value: '16:00'},
  ],
  'undefined': [],
};

export default {
  BITRIX_SEGMENTS,
  BITRIX_SCHEDULING_PERIOD,
  BITRIX_SCHEDULING_TIME,
};

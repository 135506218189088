import React from 'react';
import {MaskedInput as AntMask} from 'antd-mask-input';
import moment from 'moment';
import {Icon, Button, Form, Input, Modal, Select, notification, Row, Col, InputNumber, Divider, Avatar, DatePicker, Radio} from 'antd'; // eslint-disable-line
import PropTypes from 'prop-types';

import './styles.css';
import Container from '../Container';
import Rules from '../../utils/rules';
import constants from '../../utils/constants';
import {onlyNumbers, currencyToFloat} from '../../utils/Formatters';
import CurrencyInput from '../../components/CurrencyInput';
import barramentoService from '../../services/BarramentoService';
import parceirosErpService from '../../services/ParceirosErpService.js';
import {ReactComponent as IconWhats} from '../../assets/images/whatsapp.svg';

const Option = Select.Option;
const FormItem = Form.Item;


class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      erp: [],
      erpList: [],
      loading: false,
      maskedInputClassName: 'ant-input',
      payload: {},
      segmento: null,
      visibleModal: false,
      contentTerm: '',
      outrosERP: false,
      dataAgendamento: '',
      horarioAgendamento: '',
      periodo: '',
    };
  }

  async componentDidMount() {
    const queryParams = new window.URLSearchParams(window.location.search);
    let partnerErp = queryParams.get('parceiro_erp') || '';
    partnerErp = this.validatePartnerErp(partnerErp);

    this.setFixedSegment(queryParams.get('segmento') || '');

    const erpList = await parceirosErpService.getErpList()
        .then((response) => response.data)
        .catch((error) => {
          this.showErrorMessage('Lista de ERP não foi encontrada.', error);
          return [];
        });

    this.setState({erpList});

    if (partnerErp) {
      this.changeErpTextToCode(erpList, partnerErp);
    }
  }

  hideModal() {
    this.setState({visibleModal: false});
  }

  showModal() {
    const term = barramentoService.getTerm();
    term.then((result) => {
      this.setState({contentTerm: result.data.content_term});
      this.setState({visibleModal: true});
    });
  }

  validatePartnerErp(partnerErp) {
    const erp = decodeURI(partnerErp);
    return erp.trim().toUpperCase().replace('_', ' ');
  }

  /**
   *
   * @param {Array<{name: String,crm: String, cnpj:String}>} erpList
   * @param {String} partnerErp
   */
  changeErpTextToCode(erpList, partnerErp) {
    const founded = erpList.find((el) => el.name.substring(el.name.indexOf('|') + 1).trim().startsWith(partnerErp));

    if (founded) {
      this.props.form.setFieldsValue({'erp': founded.cnpj});
      this.setState({erpDisabled: true});
    }
  }

  setFixedSegment = (segment) => {
    segment = segment.replace(/[ _]/g, '').toUpperCase();
    const config = constants.BITRIX_SEGMENTS.find((it) => it.key === segment);
    if (config) {
      this.props.form.setFieldsValue({'segmento': config.key});
      this.setState({segmentDisabled: true});
    }
  }

  isGatewayTimeoutResponse = (response) => {
    if (response && response.status && response.status === 504) {
      return true;
    }
    return false;
  }

  handleSuccessMessage = () => {
    this.setState({loading: false});
    notification.success({
      message: 'Cadastro efetuado com sucesso! Aguarde nosso contato.',
      placement: 'bottomRight',
    });
    setTimeout(function() {
      window.location.reload();
    }, 10000);
  }
  changeSelectSchedulingPeriodo = async (periodo) => {
    await this.setState({periodo: periodo.target.value});
    this.clearSchedulingTime();
  }

  changeSelectSchedulingTime = async (time) => {
    await this.setState({horarioAgendamento: time});
  }

  clearSchedulingTime = () => {
    this.setState({horarioAgendamento: ''});
    this.props.form.setFieldsValue({hora_agendamento: ''});
  }


  submit = (event) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = this.props.form.getFieldsValue();
        const {dataAgendamento, horarioAgendamento} = this.state;
        delete payload.confirm_email;
        payload.tipo_empresa = 'LOJA';
        payload.telefone = onlyNumbers(payload.telefone);
        payload.celular = onlyNumbers(payload.celular);
        payload.faturamento = currencyToFloat(payload.faturamento);
        payload.erp = payload.erp ? payload.erp : null;
        payload.data_agendamento = dataAgendamento;
        payload.hora_agendamento = horarioAgendamento;

        this.setState({
          disabled: true,
          loading: true,
        });

        barramentoService.save(payload)
            .then((response) => {
              this.handleSuccessMessage();
            }).catch((error) => {
              if (this.isGatewayTimeoutResponse(error.response)) {
                this.handleSuccessMessage();
                return;
              }
              this.showErrorMessage(
                  'Não foi possível concluir o cadastro. Tente novamente mais tarde.',
                  error,
              );
            });
      }
    });
  }

  opcaoERP(value) {
    this.setState({outrosERP: value === null});
  }

  opcaoHorario(value) {
    this.state({horarioAgendamento: value});
  }


  outrosERP() {
    const {getFieldDecorator} = this.props.form;
    if (this.state.outrosERP) {
      return (<FormItem label='Outros'>
        {
          getFieldDecorator('outros', {
            rules: [{required: false, visible: this.state.outros}],
          })(
              <Input />,
          )
        }
      </FormItem>);
    }
  }
  preventCopyPaste = (event) => {
    event.preventDefault();
    return false;
  }

  isSameEmail = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue('email')) {
      callback('O E-mail de confirmação está diferente do e-mail informado');
    } else {
      callback();
    }
  };

  showErrorMessage(msg, error) {
    if (error && error.response && error.response.data && error.response.data.error) {
      msg += ` ${error.response.data.error}`;
    }

    notification.error({
      message: msg,
      placement: 'bottomRight',
    });

    this.setState({
      disabled: false,
      loading: false,
    });
  }

  disablePastDate = (current) => {
    return current && current < moment().startOf('day');
  }

  configInput = (component, rule) => this.props.form.getFieldDecorator(component.props.name, rule)(component);

  onDateChange(date, dateString) {
    this.setState({dataAgendamento: dateString});
  }

  render() {
    return (
      <Container loading={this.state.loading} asCard>
        <Form onSubmit={this.submit}>
          <Avatar
            shape="square"
            size={64}
            icon="shop"
            style={{position: 'absolute', left: '-61px', top: 0, color: '#e65235', backgroundColor: '#fff'}}/>
          <Row gutter={8}>
            <Col sm={8} xs={11}>
              <FormItem label='CNPJ'>
                {
                  this.configInput(<AntMask mask="11.111.111/1111-11" name="cnpj" type="tel"/>, Rules.cnpj)
                }
              </FormItem>
            </Col>

            <Col sm={16} xs={11}>
              <FormItem label="Quantidade de Caixas">
                {
                  this.configInput(<InputNumber
                    style={{width: '100%'}}
                    step={1}
                    min={1}
                    type="tel"
                    name="qtd_pdv"
                    formatter={(value) => `$ ${value}`.replace(/[^0-9]/g, '')}
                  />)
                }
              </FormItem>
            </Col>
          </Row>
          <FormItem label="Telefone Empresa">
            {
              this.configInput(<AntMask
                mask="(11) 11111-1111"
                name="telefone"
                type="tel"
                style={{width: '100%'}}/>, Rules.telefone)
            }
          </FormItem>
          <FormItem label="Segmento">
            {
              this.configInput(<Select data-testid="segmento" disabled={this.state.segmentDisabled} name="segmento">
                {
                  constants.BITRIX_SEGMENTS.map((el) =>
                    <Option key={el.key} value={el.key}>{el.value}</Option>,
                  )
                }
              </Select>, Rules.required('Segmento'))
            }
          </FormItem>
          <FormItem label="Valor de Faturamento">
            {
              this.configInput(<CurrencyInput
                placeholder="R$0,00"
                type="double"
                name="faturamento"
                style={{width: '100%'}}/>)
            }
          </FormItem>
          <FormItem label="ERP">
            {
              this.configInput(
                  <Select
                    onChange={(x) => this.opcaoERP(x)}
                    data-testid="erp-id"
                    showSearch
                    name="erp"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    notFoundContent= { `Sem resultados, pesquise outro termo ou marque a opção "Outros".`}
                    disabled={this.state.erpDisabled}>
                    {
                      this.state.erpList.map((element, key) => (<Option key={key} value={element.cnpj}>{element.name}</Option>))
                    }
                  </Select>)
            }
          </FormItem>
          {this.outrosERP()}
          <Avatar
            shape="square"
            size={64}
            icon="user"
            style={{position: 'absolute', left: '-61px', color: '#e65235', backgroundColor: '#fff'}}/>
          <Divider/>
          <FormItem label="Nome Completo">
            {
              this.configInput(<Input name="nome" />, Rules.fullname)
            }
          </FormItem>
          <FormItem label="Celular">
            {
              this.configInput(<AntMask
                mask="(11) 11111-1111"
                prefix={<Icon component={IconWhats} />}
                name="celular"
                type="tel"
                style={{width: '100%'}}/>, Rules.telefone)
            }
          </FormItem>
          <FormItem label="E-mail">
            {
              this.configInput(<Input
                onPaste={this.preventCopyPaste}
                onCopy={this.preventCopyPaste}
                name="email"/>, Rules.email)
            }
          </FormItem>
          <FormItem label="Confirme seu E-mail">
            {
              this.configInput(<Input
                onPaste={this.preventCopyPaste}
                onCopy={this.preventCopyPaste}
                name="confirm_email"/>, {rules: [...Rules.email.rules, {validator: this.isSameEmail}]})
            }
          </FormItem>
          <Divider/>
          <p>Informe a melhor data e período para entrarmos em contato com você.</p>
          <Row gutter={80}>
            <Col sm={12} xs={12}>
              <FormItem label="Data de Agendamento:" data-testid="data_agendamento">
                {
                  this.configInput(<DatePicker
                    name="data_agendamento"
                    placeholder="Data"
                    disabledDate={this.disablePastDate}
                    onChange={this.onDateChange.bind(this)}
                    format="DD/MM/YYYY"
                  />, Rules.dataAgendamento)
                }
              </FormItem>
            </Col>
            <Col sm={12} xs={12}>
              <FormItem label="Período de Agendamento:">
                {
                  this.configInput(<Radio.Group name="periodo" defaultChecked={false}>
                    <Row>
                      {
                        constants.BITRIX_SCHEDULING_PERIOD.map((el) => (
                          <Col sm={12} xs={12} key={el.key}>
                            <Radio name={el.key} value={el.key} onChange={this.changeSelectSchedulingPeriodo}>{el.value} </Radio>
                          </Col>
                        ))
                      }
                    </Row>
                  </Radio.Group>, Rules.required('Período de Agendamento:'),
                  )
                }
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label="Horário de Agendamento">
                {
                  this.configInput(<Select disabled={!this.state.periodo} name="hora_agendamento" onSelect={this.changeSelectSchedulingTime}>
                    {
                      (constants.BITRIX_SCHEDULING_TIME[this.state.periodo] || []).map((el) =>
                        <Option key={el.key} value={el.key} title={this.state.horarioAgendamento}>{el.value}</Option>,
                      )
                    }
                  </Select>, Rules.required('Horário de Agendamento'))
                }
              </FormItem>
            </Col>
          </Row>


          <div className="term">
            <p>Ao continuar, declaro estar ciente e aceito os <Button
              onClick={() => this.showModal()}
              size="small"
              type="dashed">Termos</Button> de aceite do produto.</p>
          </div>
          <Modal
            cancelText="Fechar"
            okType="secondary"
            onCancel={() => this.hideModal()}
            onOk={() => this.hideModal()}
            title="Termos de Aceite"
            visible={this.state.visibleModal}
            width="60em"
          >
            <span dangerouslySetInnerHTML={{__html: this.state.contentTerm}} />
          </Modal>
          <Button
            className="register"
            htmlType="submit"
            icon="check"
            type="primary"
            disabled={this.state.disabled}>Cadastrar</Button>
        </Form>
      </Container>
    );
  }
}

RegisterForm.propTypes = {
  form: PropTypes.object,
};

const RegisterFormWrapper = Form.create()(RegisterForm);

export default RegisterFormWrapper;

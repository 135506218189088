import React from 'react'; // eslint-disable-line

import RegisterForm from './components/RegisterForm'; // eslint-disable-line
import Header from './components/Header';
import Background from './components/Background';

import {Layout} from 'antd'; // eslint-disable-line

import './App.css';

function App() {
  return (
    <div className="App">
      <Layout>
        <Header />
        <Background>
          <RegisterForm />
        </Background>
      </Layout>
    </div>
  );
}

export default App;

const btn = {fontFamily: 'Raleway-Bold'};
const card = {boxShadow: '0 4px 8px 0 rgba(4,0,6,0.05)'};
const font = {
  default: 'Raleway',
  raleway: 'Raleway',
  ralewayBold: 'Raleway-Bold',
  ralewayMedium: 'Raleway-Medium',
  ralewaySemiBold: 'Raleway-SemiBold',
  nerisLight: 'Neris-Light',
  nerisSemiBold: 'Neris-SemiBold',
  sansSerif: 'sans-serif',
};

export default {btn, card, font};


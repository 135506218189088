class Rules {
  static required = (field, type='string') => {
    return {
      rules: [
        {type, required: true, message: (field ? `${field} é ` : 'Campo ') + 'obrigatório'},
      ],
    };
  }
  static fullname = {
    rules: [
      {required: true, message: 'Campo nome obrigatório.'},
      {
        message: 'Favor preencher com nome completo',
        validator: (rule, value, callback) => {
          const nonEmptyWords = value.split(' ').filter((x) => x.length).length;
          if (nonEmptyWords < 2) {
            return callback('Favor preencher com nome completo');
          }
          return callback();
        },
      },
    ],
  }
  static cnpj = {
    rules: [
      {type: 'string', required: true, message: 'CNPJ é obrigatório'},
      {
        validator: (rule, value, callback) => {
          if (!value) return callback();
          const cnpj = value.replace(/[^\d]+/g, '');
          if (cnpj === '') return callback();
          if (cnpj.length !== 14) {
            return callback('CNPJ inválido');
          }
          if (
            cnpj === '00000000000000' || cnpj === '11111111111111' ||
                cnpj === '22222222222222' || cnpj === '33333333333333' ||
                cnpj === '44444444444444' || cnpj === '55555555555555' ||
                cnpj === '66666666666666' || cnpj === '77777777777777' ||
                cnpj === '88888888888888' || cnpj === '99999999999999'
          ) {
            return callback('CNPJ inválido');
          }
          let tamanho = cnpj.length - 2;
          let numeros = cnpj.substring(0, tamanho);
          const digitos = cnpj.substring(tamanho);
          let soma = 0;
          let pos = tamanho - 7;
          let i = 0;
          for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
              pos = 9;
            }
          }
          let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
          if (resultado !== Number(digitos.charAt(0))) {
            return callback('CNPJ inválido');
          }
          tamanho = tamanho + 1;
          numeros = cnpj.substring(0, tamanho);
          soma = 0;
          pos = tamanho - 7;
          for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
              pos = 9;
            }
          }
          resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
          if (resultado !== Number(digitos.charAt(1))) {
            return callback('CNPJ inválido');
          }
          return callback();
        },
      },
    ],
  }
  static email = {
    rules: [
      {type: 'string', required: true, message: 'E-mail é obrigatório'},
      // eslint-disable-next-line
      {type: 'email', pattern: '/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i', message: 'E-mail inválido'},
    ],
  }
  static telefone = {
    rules: [
      {type: 'string', required: true, message: 'Telefone é obrigatório'},
      {
        validator: (rule, value, callback) => {
          if (value && value.replace( /\D+/g, '').length < 10) {
            return callback(new Error());
          }
          return callback();
        },
        message: 'Informe um telefone válido',
      },
    ],
  }
  static dataAgendamento = {
    rules: [
      {type: 'object', required: true, message: 'A data de agendamento é obrigatória'},
    ],
  }
}

export default Rules;

const safe = (text) => {
  try {
    return text.replace(/\D+/g, '');
  } catch {
    return '--';
  }
};

const onlyNumbers = (text) => safe(text);

const currencyToFloat = (text) => {
  if (!text) {
    return 0.0;
  }

  const foo = text.replace(/[a-z]\W/gi, '');
  const bar = foo.replace('.', '');
  return parseFloat(bar.replace(',', '.'));
};

export {onlyNumbers, currencyToFloat};

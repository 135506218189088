import axios from 'axios';

export default class Http {
  constructor(apiUrl, auth = true) {
    const axiosConfig = {
      headers: {
        'Accept-Type': 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: 300000,
    };
    axiosConfig['baseURL'] = apiUrl;
    this.axios = axios.create(axiosConfig);
    if (auth) {
      this.interceptorConfig();
    }
  }

  get(path, params = null) {
    return this.axios.get(path, params);
  }

  interceptorConfig = () => {
    this.axios.interceptors.request.use(this.requestConfig);
    this.axios.interceptors.response.use((response) =>
      response, this.responseErrorHandler);
  }

  post(path, params) {
    return this.axios.post(path, params);
  }

  requestConfig = (config) => {
    config.headers.authorization = 'Bearer ';
    return Promise.resolve(config);
  }

  responseErrorHandler = (error) => {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error);
  }
}

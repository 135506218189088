import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'antd';

function Background({children}) {
  return (
    <div style={{background: '#F2F2F2', paddingTop: '20px', minHeight: '91vh'}}>
      <Row type="flex" justify="center">
        {children}
      </Row>
    </div>
  );
}

Background.propTypes = {
  children: PropTypes.any,
};

export default Background;

import React from 'react';
import {Layout} from 'antd';
import logoImage from '../../assets/images/logo.svg';
const {Header} = Layout;


const CustomHeader = (props) => (
  <div>
    <Header className="header">
      <div className="logo" >
        <a href="https://www.yandeh.com.br/" target="_blank" rel="noopener noreferrer">
          <img alt='Yandeh' className='logo' src={logoImage} />
        </a>
      </div>
    </Header>
  </div>
);

export default CustomHeader;

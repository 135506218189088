import React from 'react';
import {Card, Spin, Col} from 'antd';
import PropTypes from 'prop-types';

function Container({children, loading = false, asCard = false, withTable = false, action}) {
  return (
    <Col sm={10} xxl={8} xs={24}>
      <Spin spinning={loading} tip="Aguarde.." style={{top: '-18px'}}>
        <Card bordered={false} style={{boxShadow: '0 4px 8px 0 rgba(4,0,6,0.05)', opacity: 0.9}}>
          {children}
        </Card>
      </Spin>
    </Col>
  );
}

Container.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  withstyle: PropTypes.bool,
  action: PropTypes.func,
  asCard: PropTypes.bool,
  withTable: PropTypes.bool,
};

export default Container;

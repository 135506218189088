import Http from '../utils/Http';

const apiUrl = process.env.REACT_APP_STORAGE_CONTROLLER_URL || 'https://frontend-cadastro-organico-dev.s3.amazonaws.com';
let http;

class ParceirosErpService {
  constructor() {
    http = new Http(apiUrl, false);
  }

  getErpList() {
    return http.get('/erp_partners.json');
  }
}

export default new ParceirosErpService();
